import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getApp } from "firebase/app";
import "../css/login.css";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "./header";

export default function Reset() {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const alertMsg = "Reset email requested!";
  const advice =
    "Please check your email inbox. If your address is on file, you will receive a reset email.";

  const changeEmail = (newText) => {
    const text = newText.currentTarget.value;
    setEmail(text);
  };

  const handleSubmit = async () => {
    const app = getApp();
    const auth = getAuth(app);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowAlert(true);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log("forgot: ERROR:", errorMessage);
        setShowAlert(true);
      });
  };

  const handleFinished = () => {
    navigate("/login");
  };

  return (
    <Container fluid>
      <Header login={true} />
      <Row className="login-section d-flex justify-content-center">
        <Col lg={true} className="login-box">
          <Row>
            <Form>
              <Row className="login-title">Reset Password</Row>
              <Row>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(newText) => changeEmail(newText)}
                  />
                </Form.Group>
              </Row>
              {showAlert && (
                <Row className="alert-box">
                  <Alert variant="success">{alertMsg}</Alert>
                </Row>
              )}
              {showAlert && (
                <Row className="alert-box text-center">{advice}</Row>
              )}
              <Row className="login-button">
                {showAlert ? (
                  <Button variant="secondary" onClick={() => handleFinished()}>
                    Back to Login
                  </Button>
                ) : (
                  <Button variant="primary" onClick={() => handleSubmit()}>
                    Send Reset Email
                  </Button>
                )}
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
