import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "./profileContext";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { services } from "../config/typeNames";
import MicroStatus from "./microStatus";
import { getImageURL } from "../services/svcImages";
import { Alert } from "react-bootstrap";
import { Dots } from "react-activity";
import { addDocument } from "../services/firestore";

function makeISO(d) {
  const yyyy = d.getFullYear().toString();
  const m = d.getMonth() + 1;
  let mm = m.toString();
  let dd = d.getDate().toString();
  if (mm.length < 2) {
    mm = "0" + mm;
  }
  if (dd.length < 2) {
    dd = "0" + dd;
  }
  const isoDate = yyyy + mm + dd;
  return isoDate;
}

export default function CardReview(props) {
  const navigate = useNavigate();
  const profileRecord = useContext(ProfileContext);
  const profile = profileRecord.profile.data;
  const [loaded, setLoaded] = useState(false);
  const [coupon, setCoupon] = useState();
  const [couponType, setCouponType] = useState();
  const [service, setService] = useState();
  const [imageURL, setImageURL] = useState();
  const [warning, setWarning] = useState("secondary");
  const [showAlert, setShowAlert] = useState(false);
  const [processing, setProcessing] = useState(false);

  const clickDelete = async () => {
    if (warning === "secondary") {
      setShowAlert(true);
      setWarning("danger");
    } else {
      setShowAlert(false);
      setWarning("secondary");
      setProcessing(true);
      const couponKey = coupon.clientID + "-" + coupon.type;
      const today = new Date();
      const couponRecord = {
        couponKey: couponKey,
        endedBy: profile.email,
        endedOn: makeISO(today),
      };
      await addDocument("end-coupon", couponRecord);
      setProcessing(false);
      goScreen("/admin");
    }
  };

  const clickNevermind = () => {
    setShowAlert(false);
    setWarning("secondary");
  };

  useEffect(() => {
    const loadData = async () => {
      if (props.coupon) {
        setCoupon(props.coupon);
        setCouponType(props.type);
        const imageURL = await getImageURL(props.coupon.file);
        setImageURL(imageURL);
        setService(services[props.type]);
        setLoaded(true);
      }
    };
    loadData();
  }, [props.type, props.coupon]);

  const goScreen = (urlTarget) => {
    navigate({
      pathname: urlTarget,
      search: createSearchParams({
        type: couponType,
        clientID: coupon.clientID,
      }).toString(),
    });
  };

  if (!loaded) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Manage</h3>
        </Row>
      </Col>
    );
  }

  if (coupon.phase === "active") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>Manage {service}</h3>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <Row className="text-center title-row">
            <p>Expires: {coupon.days} days after saved to wallet</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
          />
          <Row className="campaign-row">
            <Button
              className="snagm-color button-margins"
              onClick={() => goScreen("replace")}
            >
              Replace Coupon
            </Button>
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure? Press again to Deactivate...
                </Alert>
                <Button
                  className="small-bottom"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button variant={warning} onClick={() => clickDelete()}>
                Deactivate Coupon
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  if (coupon.phase === "review") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>Reviewing {service}</h3>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <Row className="text-center title-row">
            <p>Expires: {coupon.days} days after saved to wallet</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
          />
          <Row className="campaign-row">
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure? Press again to Cancel...
                </Alert>
                <Button
                  className="small-bottom"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button
                className="v-top-10"
                variant={warning}
                onClick={() => clickDelete()}
              >
                Cancel Publish Request
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  if (coupon.phase === "rejected") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>{service} Rejected</h3>
          </Row>
          <Row className="padded-row">
            <Alert variant="danger">REASON: {coupon.rejectReason}</Alert>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
          />
          <Row className="campaign-row">
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure? Press again to Cancel...
                </Alert>
                <Button
                  className="small-bottom"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button
                className="v-top-10"
                variant={warning}
                onClick={() => clickDelete()}
              >
                Clear Rejection Notice
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Bad Phase</h3>
      </Row>
    </Col>
  );
}
