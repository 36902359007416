import React from "react";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardRedeemDetail from "./cardRedeemDetail";
import { useSearchParams } from "react-router-dom";

export default function RedeemData() {
  const [searchParams] = useSearchParams();
  const clientID = searchParams.get("clientID");

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardRedeemDetail clientID={clientID} />
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
