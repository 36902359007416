import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import emptyChart from "../assets/empty-chart.jpg";
import { Chart } from "react-google-charts";

const options = {
  curveType: "function",
  legend: { position: "bottom" },
  vAxis: { minValue: 0 },
};

export default function CardRedeem(props) {
  const { clientData } = props;
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const extractChartData = (metrics) => {
    if (!metrics) {
      setChartData([]);
      setLoading(false);
      return;
    }
    if (!("redeems" in metrics)) {
      setChartData([]);
      setLoading(false);
      return;
    }
    const monthlyMetrics = metrics.redeems;
    const extractedData = [];
    for (let i = 0; i < monthlyMetrics.length; i++) {
      const month = monthlyMetrics[i].month;
      const total = Number(monthlyMetrics[i].total);
      extractedData.push([month, total]);
    }
    extractedData.splice(6);
    extractedData.unshift(["Month", "Redemptions By Month"]);
    setChartData(extractedData);
    setLoading(false);
  };

  useEffect(() => {
    extractChartData(clientData.metrics);
  }, [clientData]);

  const goScreen = (urlTarget) => {
    navigate({
      pathname: urlTarget,
      search: createSearchParams({
        clientID: clientData.clientID,
      }).toString(),
    });
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="d-flex justify-content-center">
          <div className="d-flex flex-direction-row align-items-center">
            <Button className="button-snagm fans-count">0</Button>
            <h3>Total Redemptions</h3>
          </div>
        </Row>
        <Row>
          <p className="text-center">Waiting for data...</p>
        </Row>
      </Col>
    );
  }

  if (chartData.length === 0) {
    return (
      <Col className="dash-card">
        <Row className="d-flex justify-content-center">
          <div className="d-flex flex-direction-row align-items-center">
            <Button className="button-snagm fans-count">
              {clientData.redeems ? clientData.redeems : 0}
            </Button>
            <h3>Total Redemptions</h3>
          </div>
        </Row>
        <Row className="d-flex chart-top">
          <Image src={emptyChart} fluid />
        </Row>
        <Row className="campaign-row">
          <Button className="button-campaign" onClick={() => goScreen("rdata")}>
            Redemption Records
          </Button>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="d-flex justify-content-center">
        <div className="d-flex flex-direction-row align-items-center">
          <Button className="button-snagm fans-count">
            {clientData.redeems ? clientData.redeems : 0}
          </Button>
          <h3>Total Redemptions</h3>
        </div>
      </Row>
      <Row className="d-flex chart-top">
        <Chart
          chartType="LineChart"
          width="100%"
          height="300px"
          data={chartData}
          options={options}
        />
      </Row>
      <Row className="campaign-row">
        <Button className="button-campaign" onClick={() => goScreen("rdata")}>
          Redemption Records
        </Button>
      </Row>
    </Col>
  );
}
