import React, { useContext } from "react";
import { AppContext } from "../appContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardTerms from "./cardTerms";

export default function DashTerms() {
  const user = useContext(AppContext);
  const userID = user.user.uid;

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container terms-row">
        <Col style={{ maxWidth: 800, justifySelf: "center" }}>
          <CardTerms userID={userID} />
        </Col>
      </Row>
    </Container>
  );
}
