import { readDocument, writeDocument, deleteDocument } from "./firestore";

export async function getCoupon(clientID, type, phase) {
  let collectionName = "coupons";
  if (phase === "review") {
    collectionName = "approvals";
  }
  if (phase === "rejected") {
    collectionName = "rejected";
  }
  const key = clientID + "-" + type;
  const couponData = await readDocument(collectionName, key);
  const coupon = couponData.data;
  return coupon;
}

export async function saveCoupon(clientID, coupon, type) {
  try {
    const key = clientID + "-" + type;
    const data = { data: coupon };
    await writeDocument("approvals", key, data);
    return true;
  } catch (err) {
    console.log("Error in saveCoupon: " + err.message);
    return false;
  }
}

export async function deleteCoupon(clientID, type) {
  try {
    const key = clientID + "-" + type;
    await deleteDocument("coupons", key);
    return true;
  } catch (err) {
    console.log("Error in deleteCoupon: " + err.message);
    return false;
  }
}
