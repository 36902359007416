import React from "react";
import { useParams } from "react-router-dom";

export default function Scan() {
  const { clientID } = useParams();

  return (
    <div className="App">
      <div>Scanning Client ID: {clientID}</div>
    </div>
  );
}
