import React, { useState, useContext } from "react";
import { AppContext } from "../appContext";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { addDocument } from "../services/firestore";
import { Dots } from "react-activity";
import { genTempPwd } from "../services/svcFunctions";

const emptyItem = {
  name: "",
  email: "",
  level: "Manager",
};

export default function CardUserAdminAdd(props) {
  const [updatedItem, setUpdatedItem] = useState(emptyItem);
  const [processing, setProcessing] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const clientID = props.clientID;
  const clientName = props.clientName;
  const addAdmin = props.addAdmin;
  const myContext = useContext(AppContext);
  const user = myContext.user;

  function validateEmail(email) {
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }

  const checkExistingUser = async () => {
    const data = {
      email: updatedItem.email,
    };
    const url =
      "https://us-central1-snagm-b6068.cloudfunctions.net/checkExistingUser";
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    });
    const results = await response.json();
    if (results.foundUser === "true") {
      setShowAlert(true);
      return true;
    } else {
      setShowAlert(false);
      return false;
    }
  };

  const handleChangeName = (e) => {
    const newVal = e.target.value;
    let thisValid = false;
    if (newVal.length > 0) {
      thisValid = true;
    }
    const newItem = { ...updatedItem };
    newItem.name = newVal;
    setUpdatedItem(newItem);
    setValidName(thisValid);
    if (thisValid && validEmail) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleChangeEmail = (e) => {
    const newVal = e.target.value;
    let thisValid = false;
    if (newVal.length > 0) {
      const valid = validateEmail(newVal);
      if (valid) {
        thisValid = true;
      }
    } else {
      thisValid = false;
    }
    const newItem = { ...updatedItem };
    newItem.email = newVal;
    setUpdatedItem(newItem);
    setValidEmail(thisValid);
    if (thisValid && validName) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleChangeLevel = (e) => {
    const newVal = e.target.value;
    const newItem = { ...updatedItem };
    newItem.level = newVal;
    setUpdatedItem(newItem);
  };

  const handleSave = async () => {
    setProcessing(true);
    const existingUser = await checkExistingUser();
    if (existingUser) {
      setProcessing(false);
      return;
    }
    const fullItem = { ...updatedItem };
    fullItem.clientID = clientID;
    fullItem.clientName = clientName;
    fullItem.status = "Active";
    fullItem.added = new Date().getTime();
    fullItem.addedBy = user.uid;
    fullItem.tempPwd = genTempPwd(8);
    const newRecord = {};
    newRecord.data = fullItem;
    const success = await addDocument("newUser", newRecord);
    if (!success) {
      return;
    } else {
      addAdmin(fullItem);
      setUpdatedItem(emptyItem);
      setValidated(false);
      setProcessing(false);
    }
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Add New Admin User</h3>
      </Row>
      <Row className="side-margins">
        <Row className="padded-top-20">
          <Col className="d-flex justify-content-end align-items-center">
            Name
          </Col>
          <Col className="d-flex justify-content-start grow-3">
            <Form.Control
              type="text"
              id="name"
              value={updatedItem.name}
              onChange={handleChangeName}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end align-items-center">
            Email
          </Col>
          <Col className="d-flex justify-content-start grow-3">
            <Form.Control
              type="text"
              id="email"
              value={updatedItem.email}
              onChange={handleChangeEmail}
            />
          </Col>
        </Row>
        <Row className="big-bottom">
          <Col className="d-flex justify-content-end align-items-center">
            Access
          </Col>
          <Col className="d-flex justify-content-start grow-3">
            <Form.Select onChange={handleChangeLevel} value={updatedItem.level}>
              <option value={"Manager"}>Manager</option>
              <option value={"Marketer"}>Marketer</option>
              <option value={"Viewer"}>Viewer</option>
            </Form.Select>
          </Col>
        </Row>
        {showAlert && (
          <Row>
            <Alert variant="danger">
              <p className="alert-text">
                This email address is already in use. Please use a different
                address.
              </p>
            </Alert>
          </Row>
        )}
        <Row className="padded-bottom-10">
          <Col className="d-flex justify-content-end align-items-center"></Col>
          <Col className="d-flex justify-content-start grow-3">
            {processing ? (
              <Button className="add-button" onClick={handleSave}>
                <Dots color="#FFFFFF" />
              </Button>
            ) : (
              <Button
                disabled={!validated}
                className="add-button"
                onClick={handleSave}
              >
                Add User
              </Button>
            )}
          </Col>
        </Row>
      </Row>
    </Col>
  );
}
