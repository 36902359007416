import React, { useEffect, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import emptyChart from "../assets/empty-chart.jpg";
import { Chart } from "react-google-charts";

const options = {
  legend: { position: "bottom" },
  isStacked: true,
  colors: ["#6F9DE1", "#888888"],
  vAxis: { minValue: 0 },
};

export default function CardSubs(props) {
  const { clientData } = props;
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const extractChartData = (metrics) => {
    if (!metrics) {
      setChartData([]);
      setLoading(false);
      return;
    }
    if (!("subs" in metrics)) {
      setChartData([]);
      setLoading(false);
      return;
    }
    const monthlyMetrics = metrics.subs;
    monthlyMetrics.sort((a, b) => {
      return a.isoMonth - b.isoMonth;
    });
    const extractedData = [];
    for (let i = 0; i < monthlyMetrics.length; i++) {
      const month = monthlyMetrics[i].month;
      const added = Number(monthlyMetrics[i].added);
      const lost = Number(monthlyMetrics[i].lost);
      const total = Number(monthlyMetrics[i].total);
      const changed = added - lost;
      const retained = total - changed;
      extractedData.push([month, changed, retained]);
    }
    extractedData.splice(6);
    extractedData.unshift(["Month", "Added", "Retained"]);
    setChartData(extractedData);
    setLoading(false);
  };

  useEffect(() => {
    extractChartData(clientData.metrics);
  }, [clientData]);

  if (loading) {
    return (
      <div className="dash-card">
        <Row className="d-flex justify-content-center">
          <div className="d-flex flex-direction-row align-items-center">
            <Button className="button-snagm fans-count">0</Button>
            <h3>Total Subscribers</h3>
          </div>
        </Row>
        <Row>
          <p className="text-center">Waiting for data...</p>
        </Row>
      </div>
    );
  }

  if (chartData.length === 0) {
    return (
      <div className="dash-card">
        <Row className="d-flex justify-content-center">
          <div className="d-flex flex-direction-row align-items-center">
            <Button className="button-snagm fans-count">
              {clientData.subs ? clientData.subs : 0}
            </Button>
            <h3>Total Subscribers</h3>
          </div>
        </Row>
        <Row className="chart-top">
          <Image src={emptyChart} fluid />
        </Row>
      </div>
    );
  }

  return (
    <div className="dash-card">
      <Row className="d-flex justify-content-center">
        <div className="d-flex flex-direction-row align-items-center">
          <Button className="button-snagm fans-count">
            {clientData.subs ? clientData.subs : 0}
          </Button>
          <h3>Total Subscribers</h3>
        </div>
      </Row>
      <Row className="chart-top">
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="300px"
          data={chartData}
          options={options}
        />
      </Row>
    </div>
  );
}
