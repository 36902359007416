import React, { useState, useEffect } from "react";
import IonIcon from "@reacticons/ionicons";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { shortDate, niceDate } from "../services/svcFunctions";

const activeIcon = "checkbox";
const activeIconStyle = "active-icon-style";
const activeText = "Active";
const inactiveIcon = "ellipsis-horizontal";
const inactiveIconStyle = "inactive-icon-style";
const inactiveText = "Inactive";
const reviewIcon = "eye";
const reviewIconStyle = "review-icon-style";
const reviewText = "In Review";
const rejectedIcon = "close-circle";
const rejectedIconStyle = "rejected-icon-style";
const rejectedText = "Rejected";

export default function MicroStatus(props) {
  const { phase, created, type, wallets, redeems, ends } = props;
  const [displayIcon, setDisplayIcon] = useState(inactiveIcon);
  const [displayStyle, setDisplayStyle] = useState(inactiveIconStyle);
  const [displayText, setDisplayText] = useState(inactiveText);
  const [walletSaves, setWalletSaves] = useState(0);
  const [redemptions, setRedemptions] = useState(0);
  const createdDate = shortDate(created);
  let endDate = "";
  if (ends) {
    endDate = niceDate(ends);
  }

  useEffect(() => {
    const setStyle = (phase) => {
      if (wallets) setWalletSaves(wallets);
      if (redeems) setRedemptions(redeems);

      if (phase === "active") {
        setDisplayIcon(activeIcon);
        setDisplayStyle(activeIconStyle);
        setDisplayText(activeText);
      }
      if (phase === "review") {
        setDisplayIcon(reviewIcon);
        setDisplayStyle(reviewIconStyle);
        setDisplayText(reviewText);
      }
      if (phase === "rejected") {
        setDisplayIcon(rejectedIcon);
        setDisplayStyle(rejectedIconStyle);
        setDisplayText(rejectedText);
      }
    };
    setStyle(phase);
  }, [phase, redeems, wallets]);

  if (phase === "review") {
    return (
      <React.Fragment>
        <Row className="d-flex justify-content-space-between align-items-center">
          <Col className="d-flex justify-content-left align-items-center">
            <IonIcon name={displayIcon} className={displayStyle} size="large" />
            <p>&nbsp;{displayText}</p>
          </Col>
          <Col className="d-flex right-justify">
            <p>Created: {createdDate}</p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  if (phase === "rejected") {
    return (
      <React.Fragment>
        <Row className="d-flex justify-content-space-between align-items-center">
          <Col className="d-flex justify-content-left align-items-center">
            <IonIcon name={displayIcon} className={displayStyle} size="large" />
            <p>&nbsp;{displayText}</p>
          </Col>
          <Col className="d-flex right-justify">
            <p>Created: {createdDate}</p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-space-between align-items-center">
        <Col className="d-flex justify-content-left align-items-center">
          <IonIcon name={displayIcon} className={displayStyle} size="large" />
          <p>&nbsp;{displayText}</p>
        </Col>
        {type === "discover" ? (
          <Col className="d-flex right-justify">
            <p>Ends: {endDate}</p>
          </Col>
        ) : (
          <Col className="d-flex right-justify">
            <p>Started: {createdDate}</p>
          </Col>
        )}
      </Row>
      <Row className="v-top-10">
        <Col className="d-flex align-items-center">
          <IonIcon name={"wallet"} className={reviewIconStyle} size="large" />
          <p>&nbsp;{walletSaves} Wallet Saves</p>
        </Col>
        <Col className="d-flex right-justify">
          <IonIcon name={"cash"} className={activeIconStyle} size="large" />
          <p>&nbsp;&nbsp;{redemptions} Redemptions</p>
        </Col>
      </Row>
    </React.Fragment>
  );
}
