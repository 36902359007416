import React, { useState, useEffect } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Dots } from "react-activity";
import { isoToday } from "../services/svcFunctions";
import { addDocument } from "../services/firestore";
import { getClient } from "../services/svcClient";

const emptyForm = {
  clientID: "",
  tableTents: 0,
  tableStickers: 0,
  driveThruStickers: 0,
  contactName: "",
  clientName: "",
  mailingAddr1: "",
  mailingAddr2: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  scancode: "",
};

export default function CardSignage(props) {
  const { clientID } = props;
  const [formData, setFormData] = useState(emptyForm);
  const [submitting, setSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: "inactive" });

  useEffect(() => {
    const fetchClient = async () => {
      const clientData = await getClient(clientID);
      if (clientData) {
        const newFormData = { ...formData };
        newFormData.clientID = clientData.clientID;
        newFormData.contactName = clientData.contactName;
        newFormData.clientName = clientData.clientName;
        newFormData.mailingAddr1 = clientData.mailingAddr1;
        newFormData.mailingAddr2 = clientData.mailingAddr2;
        newFormData.mailingCity = clientData.mailingCity;
        newFormData.mailingState = clientData.mailingState;
        newFormData.mailingZip = clientData.mailingZip;
        setFormData(newFormData);
      }
    };
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID]);

  const changeFormData = (e) => {
    if (
      e.target.name === "tableTents" ||
      e.target.name === "tableStickers" ||
      e.target.name === "driveThruStickers"
    ) {
      if (!e.target.value) {
        e.target.value = 0;
      }
      if (isNaN(e.target.value)) {
        return;
      }
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      e.target.value = parseInt(e.target.value);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitRequest = async () => {
    setSubmitting(true);
    if (
      formData.tableTents === 0 &&
      formData.tableStickers === 0 &&
      formData.driveThruStickers === 0
    ) {
      setAlertMessage({
        type: "danger",
        message: "Please request at least one item.",
      });
      setSubmitting(false);
      return;
    }
    //-- clean out any empty fields in formData
    for (let key in formData) {
      if (!formData[key]) {
        delete formData[key];
      }
    }
    const requestRecord = {
      data: formData,
      submitted: isoToday(),
      status: "pending",
      clientID: clientID,
    };
    try {
      await addDocument("signage", requestRecord);
      setAlertMessage({
        type: "success",
        message: "Request submitted successfully.",
      });
      setFormData(emptyForm);
      //--wait 2 seconds, then navigate to admin
      setTimeout(() => {
        window.location.href = "/admin";
      }, 2000);
    } catch (error) {
      setAlertMessage({
        type: "danger",
        message: "Sorry, something went wrong. Please try again later.",
      });
    }
    setSubmitting(false);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Signage Request</h3>
      </Row>
      <Row className="big-bottom">
        <p>
          You can request additions or replacements at any time. There's no
          charge for these items.
        </p>
      </Row>
      <Row className="big-bottom">
        <Col>
          <Image
            className="signage-pics"
            src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/signage1.jpg"
            alt="Table Tent"
          />
        </Col>
        <Col>
          <h4 className="big-bottom">Table Tents</h4>
          <p>4x6 inches</p>
          <p>Requested quantity:</p>
          <Form.Control
            className="signage-form-input"
            type="number"
            name="tableTents"
            onChange={(e) => changeFormData(e)}
            value={formData.tableTents}
          />
        </Col>
      </Row>
      <Row className="big-bottom">
        <Col>
          <Image
            className="signage-pics"
            src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/signage2.jpg"
            alt="Table Tent"
          />
        </Col>
        <Col>
          <h4 className="big-bottom">Table Stickers</h4>
          <p>2x2 inches, water resistant</p>
          <p>Requested quantity:</p>
          <Form.Control
            className="signage-form-input"
            name="tableStickers"
            type="number"
            onChange={(e) => changeFormData(e)}
            value={formData.tableStickers}
          />
        </Col>
      </Row>
      <Row className="big-bottom">
        <Col>
          <Image
            className="signage-pics"
            src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/signage3.jpg"
            alt="Table Tent"
          />
        </Col>
        <Col>
          <h4 className="big-bottom">Drive-Thru Stickers</h4>
          <p>3x5 inches, water resistant</p>
          <p>Requested quantity:</p>
          <Form.Control
            className="signage-form-input"
            type="number"
            name="driveThruStickers"
            onChange={(e) => changeFormData(e)}
            value={formData.driveThruStickers}
          />
        </Col>
      </Row>
      <Row className="padded-row">
        <hr />
      </Row>

      <Row style={{ marginBottom: 10 }}>
        <p>Your mailing address:</p>
      </Row>
      <Row>
        <Form.Control
          value={formData.contactName}
          onChange={(e) => changeFormData(e)}
          name="contactName"
          placeholder="Your Name"
          className="signage-form-input-long"
          type="text"
        />
      </Row>
      <Row>
        <Form.Control
          value={formData.clientName}
          onChange={(e) => changeFormData(e)}
          name="clientName"
          placeholder="Restaurant Name"
          className="signage-form-input-long"
          type="text"
        />
      </Row>
      <Row>
        <Form.Control
          value={formData.mailingAddr1}
          onChange={(e) => changeFormData(e)}
          name="mailingAddr1"
          placeholder="Address Line 1"
          className="signage-form-input-long"
          type="text"
        />
      </Row>
      <Row>
        <Form.Control
          value={formData.mailingAddr2}
          onChange={(e) => changeFormData(e)}
          name="mailingAddr2"
          placeholder="Address Line 2 - optional"
          className="signage-form-input-long"
          type="text"
        />
      </Row>
      <Row>
        <Form.Control
          value={formData.mailingCity}
          onChange={(e) => changeFormData(e)}
          name="mailingCity"
          placeholder="Address Line 2 - optional"
          className="signage-form-input-long"
          type="text"
        />
      </Row>
      <Row style={{ width: "75%", marginBottom: 20 }}>
        <Col>
          <Form.Control
            value={formData.mailingState}
            onChange={(e) => changeFormData(e)}
            name="mailingState"
            placeholder="State"
            className="signage-form-input-short"
            type="text"
          />
        </Col>
        <Col>
          <Form.Control
            value={formData.mailingZip}
            onChange={(e) => changeFormData(e)}
            name="mailingZip"
            placeholder="Zip Code"
            className="signage-form-input-short"
            type="text"
          />
        </Col>
        <Col></Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <p style={{ textAlign: "center" }}>
          <em>Please allow 5-7 business days for delivery.</em>
        </p>
      </Row>
      {alertMessage.type !== "inactive" && (
        <Alert variant={alertMessage.type}>
          <p style={{ textAlign: "center" }}>{alertMessage.message}</p>
        </Alert>
      )}
      {submitting ? (
        <Row className="campaign-row">
          <Button className="signage-button">
            <Dots color="#ffffff" />
          </Button>
        </Row>
      ) : (
        <Row className="campaign-row">
          <Button onClick={() => submitRequest()} className="signage-button">
            Submit Request
          </Button>
        </Row>
      )}
    </Col>
  );
}
