import React, { useState, useRef } from "react";
import "../css/cards.css";
import "react-activity/dist/library.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Dots } from "react-activity";
import { uploadImage, deleteImage } from "../services/svcImages";
import { makeLink } from "../services/svcFunctions";
import IonIcon from "@reacticons/ionicons";

const emptyItem = { nickname: "", file: "" };

export default function CardAssets(props) {
  const { assetsList, updateClientAssets } = props;
  const [badFile, setBadFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [uploadError, setUploadError] = useState(false);
  const [pendingItem, setPendingItem] = useState(emptyItem);
  const [deleting, setDeleting] = useState(false);
  const inputRef = useRef(null);

  const getURL = (filename, bucket) => {
    let bucketName = "images";
    if (bucket) {
      bucketName = bucket;
    }
    const url = makeLink(bucketName, filename);
    return url;
  };

  const handleChangeNickname = (event) => {
    const newPendingItem = { ...pendingItem };
    const nickname = event.target.value;
    newPendingItem.nickname = nickname;
    setPendingItem(newPendingItem);
    if (nickname.length > 0) {
      setUploadDisabled(false);
    } else {
      setUploadDisabled(true);
    }
  };

  const handleDeleteAsset = async (filename) => {
    if (deleting) {
      return;
    }
    setDeleting(true);
    const newAssets = [...assetsList];
    const index = newAssets.findIndex((asset) => asset.file === filename);
    newAssets.splice(index, 1);
    await deleteImage(filename);
    if (deleteImage) {
      await updateClientAssets(newAssets);
    }
    setDeleting(false);
  };

  const uploadFile = async (fileObj) => {
    try {
      //-- upload to storage
      const filename = await uploadImage(fileObj);

      //-- add to client assets array
      const newAssets = [...assetsList];
      const newAsset = { ...pendingItem };
      newAsset.file = filename;
      newAssets.unshift(newAsset);
      await updateClientAssets(newAssets);
      setUploading(false);

      //-- catch any errors
    } catch (err) {
      console.log("uploadFile: ERROR: " + err);
      setUploadError(true);
      setUploading(false);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      return;
    }
    //-- check the file type
    if (fileObj.type === "image/jpeg" || fileObj.type === "image/png") {
      setBadFile(false);
    } else {
      setBadFile(true);
      return;
    }
    //-- check the file size at 2MB
    if (fileObj.size > 2097152) {
      setBadFile(true);
      return;
    }
    //-- load the image into an img element
    const objectURL = URL.createObjectURL(fileObj);
    const img = document.createElement("img");
    img.onload = () => {
      setBadFile(false);
      setUploadDisabled(true);
      setUploading(true);
      uploadFile(fileObj);
      URL.revokeObjectURL(img.src);
    };
    img.src = objectURL;
    event.target.value = null;
    setPendingItem(emptyItem);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <Col className="d-flex grow-5">
          <h3>Creative Assets</h3>
        </Col>
        <Col className="d-flex justify-content-end"></Col>
        <p>
          Upload logos and pictures which our graphic designers can use to help
          create your coupon images.
        </p>
      </Row>
      <Row className="big-top">
        <h5>Add an Asset File</h5>
      </Row>
      <Row className="d-flex padded-row">
        <Form.Control
          value={pendingItem.nickname}
          onChange={handleChangeNickname}
          type="text"
          placeholder="Give it a nickname, like Main Logo..."
        />
      </Row>
      <Row className="campaign-row small-bottom">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />
        <Button
          disabled={uploadDisabled}
          className="med-top snagm-color"
          onClick={() => handleClick()}
        >
          {uploading ? <Dots color="#ffffff" /> : "Click to Upload"}
        </Button>
        {badFile ? (
          <Alert variant="danger" className="text-center">
            file must JPG or PNG format and less than 2MB
          </Alert>
        ) : (
          <Alert className="text-center">
            file must JPG or PNG format and less than 2MB
          </Alert>
        )}
        {uploadError && (
          <Alert variant="danger" className="text-center">
            OOPS... that didn't work
          </Alert>
        )}
      </Row>
      <hr />
      {assetsList.length === 0 && (
        <Row className="margin-sides-sm text-center">
          <Alert variant="light">No assets saved yet!</Alert>
        </Row>
      )}
      {assetsList.map((asset, index) => (
        <React.Fragment key={index}>
          <Row className="big-top">
            <Col className="grow-5">
              <p className="asset-font">{asset.nickname}</p>
            </Col>
            <Col className="d-flex justify-content-end align-items-start">
              <IonIcon
                className={"delete-icon"}
                name="trash"
                onClick={() => handleDeleteAsset(asset.file)}
              />
            </Col>
          </Row>
          <Row>
            <Image src={getURL(asset.file, asset.bucket)} fluid />
          </Row>
        </React.Fragment>
      ))}
    </Col>
  );
}
