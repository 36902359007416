import { getApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  query,
  collection,
  where,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getBlob,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

export async function writeDocument(name, key, data) {
  try {
    const app = getApp();
    if (app) {
      const db = getFirestore(app);
      await setDoc(doc(db, name, key), data);
      return data;
    }
  } catch (error) {
    console.log("writeDocument: ERROR:", error);
  }
  return null;
}

export async function addDocument(name, data) {
  try {
    const app = getApp();
    if (app) {
      const db = getFirestore(app);
      const docRef = await addDoc(collection(db, name), data);
      return docRef.id;
    }
  } catch (error) {
    console.log("addDocument: ERROR:", error);
    return null;
  }
}

export async function readDocument(name, key) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  }
  return null;
}

export async function updateDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await setDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function mergeDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await updateDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function deleteDocument(name, key) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await deleteDoc(docRef);
  }
  return null;
}

export async function queryCollection(collectionName, attribName, attribValue) {
  const app = getApp();
  const results = [];
  if (app) {
    const db = getFirestore(app);
    const q = query(
      collection(db, collectionName),
      where(attribName, "==", attribValue)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const thisDoc = doc.data();
      thisDoc.id = doc.id;
      results.push(thisDoc);
    });
  }
  return results;
}

//-- download a file from storage and uplaod to a different folder
export async function moveFile(filename, fromFolder, toFolder) {
  const bucket = "gs://snagm-b6068.appspot.com/";
  const fromPath = bucket + fromFolder + "/" + filename;
  const toPath = bucket + toFolder + "/" + filename;
  try {
    const app = getApp();
    if (app) {
      // Create a reference from a Google Cloud Storage URI
      const storage = getStorage();
      const downRef = ref(storage, fromPath);
      // Download to BLOB
      const fileBlob = await getBlob(downRef);
      // Upload BLOB to new folder
      const uploadRef = ref(storage, toPath);
      uploadBytes(uploadRef, fileBlob).then((snapshot) => {
        console.log("Moved a file successfully!");
      });
    }
  } catch (error) {
    console.log("moveFile: ERROR:", error);
  }
}

//-- delete a file from storage
export async function deleteStorage(filename, folder) {
  const storage = getStorage();
  const deleteRef = ref(storage, folder + "/" + filename);
  await deleteObject(deleteRef)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log("deleteObject: ERROR:", error);
      return false;
    });
}
