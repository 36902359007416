import { getApp } from "firebase/app";
import { ref, getDownloadURL, getStorage, uploadBytes } from "firebase/storage";
import { genID } from "../services/svcFunctions";
import { deleteStorage } from "../services/firestore";

export const getImageURL = async (filename) => {
  try {
    const folderPath = "images/" + filename;
    const storage = getStorage(getApp());
    const storageRef = ref(storage, folderPath);
    const imageURL = await getDownloadURL(storageRef);
    return imageURL;
  } catch (err) {
    return null;
  }
};

export const uploadImage = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "images/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};

export const deleteImage = async (filename) => {
  const result = await deleteStorage(filename, "images");
  return result;
};

export const getPhotoURL = async (filename) => {
  try {
    const folderPath = "photos/" + filename;
    const storage = getStorage(getApp());
    const storageRef = ref(storage, folderPath);
    const imageURL = await getDownloadURL(storageRef);
    return imageURL;
  } catch (err) {
    return null;
  }
};

export const uploadPhotos = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "photos/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};
