import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import "react-activity/dist/library.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Dots } from "react-activity";

const warningTitle = "Please enter a title";
const warningTag = "Please enter a tagline";
const warningTerms = "Please enter terms";
const warningImage = "Please select an image";

export default function CardPreview(props) {
  const { title, tagline, terms, imageURL, validImage, publishCoupon } = props;
  const navigate = useNavigate();
  const [warningMessages, setWarningMessages] = useState([]);
  const [published, setPublished] = useState(false);
  const [processing, setProcessing] = useState(false);

  const goScreen = (urlTarget) => {
    navigate({
      pathname: urlTarget,
    });
  };

  const handleSubmit = () => {
    setProcessing(true);
    const isReady = checkSubmit();
    if (isReady) {
      publishCoupon();
      setPublished(true);
    }
    setProcessing(false);
  };

  const handleOK = () => {
    goScreen("/admin");
  };

  const checkSubmit = () => {
    let ready = false;
    const newWarning = [];
    if (title === "") {
      newWarning.push(warningTitle);
    }
    if (tagline === "") {
      newWarning.push(warningTag);
    }
    if (terms === "") {
      newWarning.push(warningTerms);
    }
    if (!validImage) {
      newWarning.push(warningImage);
    }
    setWarningMessages(newWarning);
    if (newWarning.length === 0) {
      ready = true;
    }
    return ready;
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Preview</h3>
      </Row>

      <Row>
        <Image src={imageURL} />
      </Row>

      <Row className="text-center padded-top coupon-title">
        <p>{title}</p>
      </Row>
      <Row className="text-center coupon-subtitle">
        <p>{tagline}</p>
      </Row>

      <Row className="text-center title-row coupon-terms">
        <p>{terms}</p>
      </Row>
      <Row className="text-center v-padding-10 title-row">
        <p className="tiny-text">Expires: 14 days after saved to Wallet</p>
      </Row>
      {warningMessages.length > 0 && (
        <Alert className="text-center" variant="danger">
          {warningMessages.map((message) => (
            <p key={message}>{message}</p>
          ))}
        </Alert>
      )}
      {published && (
        <Alert className="text-center" variant="success">
          <p className="bigger-text">Successfully Submitted for Approval</p>
          <p className="details-font">
            Typical review time is 1 business day. You will receive an email as
            soon as your coupon is approved and published.
          </p>
        </Alert>
      )}
      <Row className="padded-row">
        {published ? (
          <Button onClick={() => handleOK()} className="snagm-color">
            OK
          </Button>
        ) : (
          <Button
            onClick={() => handleSubmit()}
            disabled={processing}
            variant="success"
          >
            {processing ? (
              <Dots size={20} color="#EEEEF1" />
            ) : (
              <p>Submit to Publish</p>
            )}
          </Button>
        )}
      </Row>
    </Col>
  );
}
