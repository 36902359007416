import React from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CardBuyCredits() {
  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Purchase Design Credits</h3>
      </Row>
      <Row>
        <p className="text-center">Coming Soon!</p>
      </Row>
    </Col>
  );
}
