import React from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { services } from "../config/typeNames";

const publishTerms = {
  discover: "Will be published for 90 days or until manually ended.",
  inbox: "Will be published for 30 days or until manually ended.",
  birthday: "Will be published for 365 days or until manually ended.",
};

export default function CardNewCoupon(props) {
  const {
    type,
    title,
    tagline,
    terms,
    changeTerms,
    changeTagline,
    changeTitle,
  } = props;

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <Col className="d-flex grow-5">
          <h3>{services[type]}</h3>
        </Col>
        <Col className="d-flex justify-content-end"></Col>
      </Row>
      <Form>
        <Row className="padded-row">
          <Form.Control
            value={title}
            onChange={changeTitle}
            placeholder="Title"
          />
        </Row>
        <Row className="padded-row-top">
          <Form.Control
            value={tagline}
            onChange={changeTagline}
            placeholder="Tagline"
          />
        </Row>
        <Row className="padded-row-top">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Terms and Conditions"
            onChange={changeTerms}
            value={terms}
          />
        </Row>
        <Row className="med-top">
          <p className="text-center tiny-text">{publishTerms[type]}</p>
        </Row>
      </Form>
    </Col>
  );
}
