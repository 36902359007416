import React from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default function CardTemplate() {
  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Template</h3>
      </Row>
      <Row>
        <p>something here</p>
      </Row>
      <Row>
        <p>something here</p>
      </Row>
      <Row className="campaign-row">
        <Button className="button-campaign">Manage Card</Button>
      </Row>
    </Col>
  );
}
