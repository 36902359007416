import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getProfile, writeProfile } from "../services/svcUsers";

export default function CardTerms(props) {
  const { userID } = props;
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  const handleAgree = async () => {
    setProcessing(true);
    const profile = await getProfile(userID);
    const profileData = { ...profile.data };
    profileData.agreedToTerms = new Date().toISOString();
    profile.data = { ...profileData };
    const successful = await writeProfile(userID, profile);
    if (successful) {
      navigate("/admin");
    } else {
      alert(
        "There was an error processing your agreement. Please try again later."
      );
      setProcessing(false);
    }
  };

  return (
    <Col style={{ marginBottom: 40 }} className="dash-card">
      <Row className="title-row">
        <h3>Terms and Conditions</h3>
      </Row>
      <Row>
        <p>
          These Terms and Conditions (“Terms”) govern your relationship between
          Bold Intermedia LLC (“Company”), who provides the services known as
          "Snagm Digital Coupons." By using any of our services, you agree to be
          bound to these Terms. If you do not agree to these Terms, do not use
          our services.
        </p>
        <Row style={{ marginTop: 20 }}>
          <h6>DEFINITIONS</h6>
        </Row>
        <Row style={{ marginLeft: 10 }}>
          <ul>
            <li>
              “You” refers to both the authorized contact and the business
              entity they represent when entering into this agreement
            </li>
            <li>“App” refers to the Snagm Digital Coupons mobile app</li>
            <li>
              “Services” refers to the related services and web sites which
              enable use of the App and the Content
            </li>
            <li>
              “Content” refers to any text, images, video, audio, or other media
              available through the Service or the App
            </li>
          </ul>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <h5>By using our Services You agree that:</h5>
        </Row>
        <Row style={{ marginLeft: 10 }}>
          <ol>
            <li style={{ marginBottom: 10 }}>
              Any feedback, suggestions, or ideas that You provide regarding the
              App or the Services provided by the Company shall become the
              exclusive property of the Company. The Company may use, reproduce,
              disclose, and/or distribute any of the provided information for
              any purpose without restriction or compensation to You. You hereby
              assign to the Company all rights, title and interest in and to any
              feedback, suggestions, or ideas You provide.
            </li>
            <li style={{ marginBottom: 10 }}>
              All rights, title, and interest in and to the App, including its
              Content and any associated intellectual property rights, are the
              exclusive property of the Company and its licensors. You may not
              reproduce, distribute, or create derivative works of our Services,
              App, or Content without the express written permission of the
              Company.
            </li>
            <li>
              <p>You will not:</p>
              <ul>
                <li>
                  Use the Services for any unlawful or fraudulent purposes.
                </li>
                <li>
                  Copy, modify, adapt, or create derivative works of the App,
                  Services, or Content.
                </li>
                <li>
                  Interfere with, disrupt, or overload the App, Services, or
                  underlying infrastructure.
                </li>
                <li>
                  Attempt to gain unauthorized access to the Service, App or any
                  associated systems or networks.
                </li>
              </ul>
            </li>
            <li style={{ marginTop: 10 }}>
              THE APP AND ALL ASSOCIATED SERVICES ARE PROVIDED "AS IS" AND "AS
              AVAILABLE," WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
              INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE
              COMPANY DOES NOT WARRANT THAT THE APP OR SERVICES WILL BE
              UNINTERRUPTED, ERROR-FREE, OR COMPLETELY SECURE.
            </li>
            <li style={{ marginTop: 10 }}>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY
              SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
              SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
              THE USE OF THE APP OR SERVICES, EVEN IF ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGES.
            </li>
            <li style={{ marginTop: 10 }}>
              These Terms shall be governed by and construed in accordance with
              the laws of Bexar County, Texas, without regard to its conflict of
              laws principles.
            </li>
            <li style={{ marginTop: 10 }}>
              The Company reserves the right to modify these Terms at any time,
              in its sole discretion. Your continued use of the App and/or
              Services following any modification constitutes your acceptance of
              the modified Terms.
            </li>
            <li style={{ marginTop: 10 }}>
              If you have any questions or concerns, please contact us
              at:&nbsp;&nbsp;
              <b>legal@boldintermedia.com</b>
            </li>
          </ol>
        </Row>
      </Row>
      <Row className="campaign-row">
        <Button
          disabled={processing}
          onClick={() => handleAgree()}
          className="button-campaign"
        >
          I AGREE
        </Button>
      </Row>
    </Col>
  );
}
