import React, { useEffect, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { readDocument } from "../services/firestore";

export function prettyDate(iso) {
  if (iso === undefined) return "";
  const yyyy = iso.substring(0, 4);
  const mm = iso.substring(4, 6);
  const dd = iso.substring(6, 8);
  const formattedDate = mm + "/" + dd + "/" + yyyy;
  return formattedDate;
}

const niceDate = (dateString) => {
  const strDate = dateString.toString();
  if (strDate.length === 8) {
    return prettyDate(strDate);
  }
  const realDate = new Date(dateString).toLocaleDateString();
  return realDate;
};

export default function CardHistory(props) {
  const { clientID, type } = props;
  const couponKey = `${clientID}-${type}`;
  const [couponList, setCouponList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const historyDoc = await readDocument("history", couponKey);
      if (historyDoc) {
        setCouponList(historyDoc.data);
      }
      setLoading(false);
    };
    loadData();
  }, [couponKey]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>History</h3>
        </Row>
      </Col>
    );
  }

  if (couponList.length === 0) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>History</h3>
        </Row>
        <Row>
          <p className="text-center">- - None yet - -</p>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>History</h3>
      </Row>
      {couponList.map((coupon, index) => {
        return (
          <Row key={index} className="history-window">
            <Row className="text-center history-title">
              <p>{coupon.title}</p>
            </Row>
            <Row className="text-center coupon-subtitle">
              <p>{coupon.tag}</p>
            </Row>
            <Row className="text-center v-padding-20">
              <p>Created: {niceDate(coupon.created)}</p>
              <p>Archived: {niceDate(coupon.archived)}</p>
              <p>Redemptions: {coupon.redeems ? coupon.redeems : 0}</p>
            </Row>
          </Row>
        );
      })}
    </Col>
  );
}
