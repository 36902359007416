import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "./profileContext";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getClient } from "../services/svcClient";

const orderPhaseList = {
  ready: "Your design is ready for review!",
  pending: "A designer is being assigned...",
  complete: "New design added to your library.",
  working: "We are working on your design...",
};

const niceDate = (dateString) => new Date(dateString).toLocaleDateString();

export default function CardOrders() {
  const navigate = useNavigate();
  const profileContext = useContext(ProfileContext);
  const profile = profileContext.profile;
  const [ordersList, setOrdersList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadOrders = async () => {
      let clientData = null;
      if (profile) {
        clientData = await getClient(profile.clientID);
      }
      if (clientData) {
        if ("orders" in clientData) {
          let orders = clientData.orders;
          orders.forEach((order) => {
            order.desc = orderPhaseList[order.phase];
          });
          //-- sort list by orders.completed
          orders.sort((a, b) => {
            return new Date(b.completed) - new Date(a.completed);
          });
          //-- limit the list to the last 6 orders
          orders = orders.slice(0, 6);
          setOrdersList(orders);
        }
      }
    };
    loadOrders();
    setLoaded(true);
  }, [profile]);

  const goOrder = (mode, id) => {
    if (id === undefined) {
      id = "";
    }
    navigate({
      pathname: "order",
      search: createSearchParams({
        id: id,
        m: mode,
      }).toString(),
    });
  };

  const createSummary = (order) => {
    let summary = "";
    if (order.phase === "complete") {
      summary = order.type + " - Completed: " + niceDate(order.completed);
      return summary;
    } else {
      summary = order.type + " - Requested: " + niceDate(order.submitted);
    }
    return summary;
  };

  return (
    <Col className="dash-card">
      <Row>
        <h3>Graphic Design</h3>
      </Row>
      {loaded && (
        <React.Fragment>
          {ordersList.map((order, index) => (
            <React.Fragment key={index}>
              <Row>
                <p
                  onClick={() => goOrder(order.phase, order.orderID)}
                  className="link-style"
                >
                  {createSummary(order)}
                </p>
                <p>
                  <b>{order.desc}</b>
                </p>
              </Row>
              <Row className="padded-row v-top-10">
                <hr />
              </Row>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
      <Row className="campaign-row">
        <Button
          onClick={() => goOrder("new", profile.clientID)}
          className="button-campaign"
        >
          Place an Image Order
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button onClick={() => goOrder("buy")} className="button-campaign">
          Purchase Design Credits
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button onClick={() => navigate("assets")} className="button-campaign">
          Manage Creative Assets
        </Button>
      </Row>
    </Col>
  );
}
