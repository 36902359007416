import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { getOrder } from "../services/svcOrders";
import { services } from "../config/typeNames";
import { getImageURL, getPhotoURL } from "../services/svcImages";

const orderPhaseList = {
  ready: "Your design is ready for review!",
  pending: "A designer is being assigned...",
  complete: "Completed and added to your library.",
  working: "We are working on your design...",
};

export default function CardOrderView() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const phase = searchParams.get("m");
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getOrderData = async () => {
      const orderRecord = await getOrder(phase, id);
      const orderData = orderRecord.data;
      setOrder(orderData);
      if ("designerFile" in orderData) {
        if (phase === "complete") {
          const url = await getImageURL(orderData.designerFile);
          if (url) {
            setImageURL(url);
          }
        } else {
          const url = await getPhotoURL(orderData.designerFile);
          if (url) {
            setImageURL(url);
          }
        }
      }
      setLoading(false);
    };
    getOrderData();
  }, [phase, id]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Order View</h3>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Order View</h3>
        <h5>{services[order.couponType]} Coupon</h5>
        <p>Status: {orderPhaseList[phase]}</p>
      </Row>
      {imageURL && (
        <Row>
          <Image src={imageURL} />
        </Row>
      )}
    </Col>
  );
}
