import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../appContext";
import { useSearchParams } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardNewCoupon from "./cardNewCoupon";
import CardPreview from "./cardPreview";
import CardLibrary from "./cardLibrary";
import {
  genID,
  getEndDate,
  isoToday,
  makeLink,
} from "../services/svcFunctions";
import { getClient, updateClient } from "../services/svcClient";
import { saveCoupon } from "../services/svcCoupon";

const defaultImage = "empty-choose.jpg";

export default function DashCreate() {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const clientID = searchParams.get("clientID");
  const [clientData, setClientData] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [title, setTitle] = useState("");
  const [tagline, setTagline] = useState("");
  const [terms, setTerms] = useState("");
  const [validImage, setValidImage] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState(makeLink("public", defaultImage));
  const termsLoaded = useRef(false);

  const publishCoupon = async () => {
    let couponID = "";
    let ends = getEndDate(365);
    const timeNow = new Date().getTime();
    if (type === "inbox") {
      couponID = clientID + "-" + genID(5);
      ends = getEndDate(30);
    } else {
      couponID = clientID + "-" + type;
    }
    if (type === "discover") {
      ends = getEndDate(90);
    }
    const coupon = {
      clientID: clientID,
      clientName: clientData.clientName,
      id: couponID,
      file: imageFile,
      logo: clientData.logo,
      title: title,
      tag: tagline,
      terms: terms,
      starts: isoToday(),
      ends: ends,
      style: "image",
      phase: "review",
      type: type,
      created: timeNow,
      createdby: user.uid,
      notify: user.email,
    };
    if (type === "discover") {
      coupon.zips = clientData.radius ? clientData.radius : [];
    }
    const result = await saveCoupon(clientID, coupon, type);
    if (result) {
      updateClientCoupons();
    }
  };

  const updateClientCoupons = async () => {
    const newClientData = { ...clientData };
    if (!("camapigns" in newClientData)) {
      newClientData.campaigns = {};
    }
    const newCoupons = newClientData.campaigns;
    newCoupons[type] = "review";
    newClientData.campaigns = newCoupons;
    updateClient(clientID, newClientData);
  };

  const changeTitle = (e) => {
    setTitle(e.target.value);
  };

  const changeTagline = (e) => {
    setTagline(e.target.value);
  };

  const changeTerms = (e) => {
    setTerms(e.target.value);
  };

  const changeImageFile = async (filename) => {
    setImageFile(filename);
    const image = makeLink("images", filename);
    setImageURL(image);
    setValidImage(true);
  };

  const changeClientData = async (clientData) => {
    await updateClient(clientID, clientData);
    setClientData(clientData);
  };

  useEffect(() => {
    const startHere = async () => {
      const client = await getClient(clientID);
      setClientData(client);
      if (!termsLoaded.current) {
        setTerms(client.terms);
        termsLoaded.current = true;
      }
      setIsReady(true);
    };
    startHere();
  }, [clientID, imageFile]);

  return (
    <Container fluid>
      <Header />
      {isReady && (
        <Row className="grid-container main-row">
          <Col>
            <CardNewCoupon
              type={type}
              title={title}
              tagline={tagline}
              terms={terms}
              changeTerms={changeTerms}
              changeTagline={changeTagline}
              changeTitle={changeTitle}
            />
          </Col>
          <Col>
            <CardPreview
              clientID={clientID}
              type={type}
              title={title}
              tagline={tagline}
              terms={terms}
              imageURL={imageURL}
              validImage={validImage}
              publishCoupon={publishCoupon}
            />
          </Col>
          <Col>
            <CardLibrary
              clientData={clientData}
              type={type}
              changeImageFile={changeImageFile}
              changeClientData={changeClientData}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
