import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardReview from "./cardReview";
import CardHistory from "./cardHistory";
import { getCoupon } from "../services/svcCoupon";

export default function DashReview() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const clientID = searchParams.get("clientID");
  const phase = searchParams.get("phase");
  const [coupon, setCoupon] = useState();
  const [loading, setLoading] = useState(true);

  //-- load the coupon data here and pass down to various components
  useEffect(() => {
    const loadData = async () => {
      if (type && clientID) {
        const couponData = await getCoupon(clientID, type, phase);
        setCoupon(couponData);
        setLoading(false);
      }
    };
    loadData();
  }, [type, clientID, phase]);

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row"></Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardReview coupon={coupon} type={type} />
        </Col>
        <Col>
          <CardHistory clientID={coupon.clientID} type={type} />
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
