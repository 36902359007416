import React, { useState, useEffect, useContext } from "react";
import { ProfileContext } from "./profileContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardUserAdmins from "./cardUserAdmins";
import CardUserAdminAdd from "./cardUserAdminAdd";
import { getUserList } from "../services/svcUsers";
import { updateDocument } from "../services/firestore";

export default function DashUserAdmin() {
  const profileRecord = useContext(ProfileContext);
  const profileData = profileRecord.profile;
  const clientID = profileData.clientID;
  const clientName = profileData.data.clientName;
  const [adminsList, setAdminsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const userList = await getUserList(clientID);
      setAdminsList(userList);
      setLoading(false);
    };
    fetchData();
  }, [clientID]);

  const saveChanges = async (updatedItem, showItem) => {
    const newList = [...adminsList];
    newList[showItem] = updatedItem;
    const newRecord = { clientID: updatedItem.clientID, data: updatedItem };
    const success = await updateDocument("users", updatedItem.id, newRecord);
    if (!success) {
      return;
    }
    setAdminsList(newList);
  };

  const addAdmin = (newItem) => {
    const newList = [...adminsList];
    newList.unshift(newItem);
    setAdminsList(newList);
  };

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardUserAdmins
            adminsList={adminsList}
            loading={loading}
            saveChanges={saveChanges}
          />
        </Col>
        <Col>
          <CardUserAdminAdd
            clientID={clientID}
            clientName={clientName}
            addAdmin={addAdmin}
          />
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
