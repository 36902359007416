import React, { useState, useEffect, useContext, useMemo } from "react";
import { ProfileContext } from "./profileContext";
import { useNavigate, createSearchParams } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import "react-activity/dist/library.css";
import { Dots } from "react-activity";
import { getCoupon } from "../services/svcCoupon";
import MicroStatus from "./microStatus";
import { addDocument, deleteDocument } from "../services/firestore";
import { wait, makeISO } from "../services/svcFunctions";
import { getImageURL } from "../services/svcImages";
import { getClient, writeClient } from "../services/svcClient";

export default function CardBirthday(props) {
  const navigate = useNavigate();
  const profileRecord = useContext(ProfileContext);
  const profile = profileRecord.profile.data;
  const couponType = "birthday";
  const clientData = props.clientData;
  const clientID = clientData.clientID;
  const campaigns = useMemo(
    () => (clientData.campaigns ? clientData.campaigns : {}),
    [clientData]
  );
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("inactive");
  const [coupon, setCoupon] = useState();
  const [imageURL, setImageURL] = useState();
  const [warning, setWarning] = useState("secondary");
  const [showAlert, setShowAlert] = useState(false);
  const [processing, setProcessing] = useState(false);

  const clearRejected = async () => {
    setProcessing(true);

    //-- delete the coupon record in the rejected collection
    const couponKey = coupon.clientID + "-" + coupon.type;
    await deleteDocument("rejected", couponKey);

    //-- update the client document
    const updatedClient = await getClient(clientID);
    const newCampaigns = { ...updatedClient.campaigns };
    newCampaigns.birthday = "inactive";
    updatedClient.campaigns = newCampaigns;
    await writeClient(updatedClient);

    //-- reset the status to inactive
    await wait(1000);
    setProcessing(false);
    setStatus("inactive");
  };

  const cancelRequest = async () => {
    if (warning === "secondary") {
      setShowAlert(true);
      setWarning("danger");
    } else {
      setShowAlert(false);
      setWarning("secondary");
      setProcessing(true);

      //-- delete the coupon record in the approvals collection
      const couponKey = coupon.clientID + "-" + coupon.type;
      await deleteDocument("approvals", couponKey);

      //-- update the client document
      const updatedClient = await getClient(clientID);
      const newCampaigns = { ...updatedClient.campaigns };
      newCampaigns.birthday = "inactive";
      updatedClient.campaigns = newCampaigns;
      await writeClient(updatedClient);

      //-- reset the status to inactive
      await wait(1000);
      setProcessing(false);
      setStatus("inactive");
    }
  };

  const clickDelete = async () => {
    if (warning === "secondary") {
      setShowAlert(true);
      setWarning("danger");
    } else {
      setShowAlert(false);
      setWarning("secondary");
      setProcessing(true);
      const couponKey = coupon.clientID + "-" + coupon.type;
      const today = new Date();
      const couponRecord = {
        couponKey: couponKey,
        endedBy: profile.email,
        endedOn: makeISO(today),
      };
      addDocument("end-coupon", couponRecord);
      await wait(1000);
      setProcessing(false);
      setStatus("inactive");
    }
  };

  const clickNevermind = () => {
    setShowAlert(false);
    setWarning("secondary");
  };

  const goScreen = (urlTarget) => {
    navigate({
      pathname: urlTarget,
      search: createSearchParams({
        type: couponType,
        clientID: clientID,
      }).toString(),
    });
  };

  useEffect(() => {
    const loadData = async () => {
      //-- first determine the status of the coupon
      let currentStatus = "inactive";
      if ("birthday" in campaigns) {
        currentStatus = campaigns.birthday;
      }
      setStatus(currentStatus);
      //-- if the current status is active go get the coupon data
      if (currentStatus !== "inactive") {
        const couponData = await getCoupon(clientID, "birthday", currentStatus);
        setCoupon(couponData);
        const imageURL = await getImageURL(couponData.file);
        setImageURL(imageURL);
      }
      setLoading(false);
    };
    loadData();
  }, [campaigns, clientID]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Birthday Coupon</h3>
        </Row>
        <Row>
          <div className="center-stuff">
            <Dots />
          </div>
        </Row>
        <Row className="campaign-row">
          <Button disabled className="button-campaign">
            Loading...
          </Button>
        </Row>
      </Col>
    );
  }

  if (status === "inactive") {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Birthday Coupon</h3>
        </Row>
        <Row>
          <h6>INACTIVE</h6>
        </Row>
        <Row>
          <p>
            Create a coupon that subscribers will see in their Inbox from 1 week
            before to 1 week after their birthday. This coupon will remain
            published for one year or until manually cancelled. This coupon can
            only be redeemed once per year. All coupons expire 14 days after
            being saved to the Wallet.
          </p>
        </Row>
        <Row className="campaign-row">
          <Button
            onClick={() => goScreen("create")}
            className="button-campaign"
          >
            Create Now
          </Button>
        </Row>
      </Col>
    );
  }

  if (coupon.phase === "active") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>Birthday Coupon</h3>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <Row className="text-center title-row">
            <p>Expires: 14 days after saved to wallet</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
            ends={coupon.ends}
          />
          <Row className="campaign-row">
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure?
                </Alert>
                <Button
                  className="v-top-10"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button className="v-top-10" variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button
                className="big-top"
                variant={warning}
                onClick={() => clickDelete()}
              >
                End Publishing Now
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  if (coupon.phase === "review") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>Birthday Coupon</h3>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <Row className="text-center title-row">
            <p>Expires: 14 days after saved to wallet</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
            ends={coupon.ends}
          />
          <Row className="campaign-row">
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure?
                </Alert>
                <Button
                  className="small-bottom"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button className="v-top-10" variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button
                className="v-top-10"
                variant={warning}
                onClick={() => cancelRequest()}
              >
                Cancel Publish Request
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  if (coupon.phase === "rejected") {
    return (
      <React.Fragment>
        <Col className="dash-card">
          <Row className="title-row">
            <h3>Birthday Coupon</h3>
          </Row>
          <Row className="padded-row">
            <Alert variant="danger">Rejected For: {coupon.rejectReason}</Alert>
          </Row>
          <Row className="title-row">
            <Image src={imageURL} />
          </Row>
          <Row className="text-center coupon-title">
            <p>{coupon.title}</p>
          </Row>
          <Row className="text-center coupon-subtitle">
            <p>{coupon.tag}</p>
          </Row>
          <Row className="text-center title-row coupon-terms">
            <p>{coupon.terms}</p>
          </Row>
          <MicroStatus
            phase={coupon.phase}
            created={coupon.created}
            type={couponType}
            redeems={coupon.redeems}
            wallets={coupon.wallets}
            ends={coupon.ends}
          />
          <Row className="campaign-row">
            {showAlert && (
              <React.Fragment>
                <Alert variant="danger" className="button-margins">
                  Are you sure?
                </Alert>
                <Button
                  className="small-bottom"
                  variant="secondary"
                  onClick={() => clickNevermind()}
                >
                  Nevermind
                </Button>
              </React.Fragment>
            )}
            {processing ? (
              <Button className="v-top-10" variant={warning}>
                <Dots color="white" />
              </Button>
            ) : (
              <Button
                className="v-top-10"
                variant={warning}
                onClick={() => clearRejected()}
              >
                Clear Rejection Notice
              </Button>
            )}
          </Row>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Ooops... bad data here.</h3>
      </Row>
    </Col>
  );
}
