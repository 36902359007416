import React, { useState, useContext } from "react";
import { ProfileContext } from "./profileContext";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Dots } from "react-activity";
import Badge from "react-bootstrap/Badge";

export default function CardUserAdmins(props) {
  const profile = useContext(ProfileContext);
  const profileData = profile.profile.data;
  const [showEdit, setShowEdit] = useState(false);
  const [showItem, setShowItem] = useState(-1);
  const [updatedItem, setUpdatedItem] = useState({});
  const adminsList = props.adminsList;
  const saveChanges = props.saveChanges;
  const loading = props.loading;

  const toggleEdit = (index, level) => {
    if (level === "Owner" && profileData.level !== "Owner") {
      return;
    }
    if (showEdit) {
      setShowEdit(false);
      setShowItem(-1);
      setUpdatedItem({});
    } else {
      setShowEdit(true);
      setShowItem(index);
      setUpdatedItem(adminsList[index]);
    }
  };

  const handleChangeName = (e) => {
    const newVal = e.target.value;
    const newItem = { ...updatedItem };
    newItem.name = newVal;
    setUpdatedItem(newItem);
  };

  const handleChangeEmail = (e) => {
    const newVal = e.target.value;
    const newItem = { ...updatedItem };
    newItem.email = newVal;
    setUpdatedItem(newItem);
  };

  const handleChangeLevel = (e) => {
    const newVal = e.target.value;
    const newItem = { ...updatedItem };
    newItem.level = newVal;
    setUpdatedItem(newItem);
  };

  const handleChangeStatus = (e) => {
    const newVal = e.target.value;
    const newItem = { ...updatedItem };
    newItem.status = newVal;
    setUpdatedItem(newItem);
  };

  const handleSave = async () => {
    await saveChanges(updatedItem, showItem);
    toggleEdit();
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Admin Users</h3>
        </Row>
        <Row className="d-flex title-row margin-left-20">
          <Dots color="#000000" size={24} speed={1} animating={true} />
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Admin Users</h3>
      </Row>
      <Row className="title-row tiny-text">
        <Col className="d-flex justify-content-start align-items-center">
          <p>NAME</p>
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <p>LEVEL</p>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <p>STATUS</p>
        </Col>
      </Row>
      {adminsList.map((admin, index) => (
        <React.Fragment key={index}>
          <Row className="title-row">
            <Col className="d-flex justify-content-start align-items-center">
              <p>{admin.name}</p>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <p>{admin.level}</p>
            </Col>
            {admin.status === "Active" ? (
              <Col className="d-flex justify-content-end align-items-center">
                <Badge
                  bg="success"
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => toggleEdit(index, admin.level)}
                >
                  {admin.status}
                </Badge>
              </Col>
            ) : (
              <Col className="d-flex justify-content-end align-items-center">
                <Badge
                  bg="warning"
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => toggleEdit(index, admin.level)}
                >
                  {admin.status}
                </Badge>
              </Col>
            )}
          </Row>
          {index === showItem && (
            <Row className="title-row side-margins">
              <Row className="highlighted padded-top-10">
                <Col className="d-flex justify-content-end align-items-center">
                  Name
                </Col>
                <Col className="d-flex justify-content-start grow-3">
                  <Form.Control
                    type="text"
                    id="name"
                    value={updatedItem.name}
                    onChange={handleChangeName}
                  />
                </Col>
              </Row>
              <Row className="highlighted">
                <Col className="d-flex justify-content-end align-items-center">
                  Email
                </Col>
                <Col className="d-flex justify-content-start grow-3">
                  <Form.Control
                    type="text"
                    id="email"
                    value={updatedItem.email}
                    onChange={handleChangeEmail}
                  />
                </Col>
              </Row>
              {updatedItem.level !== "Owner" && (
                <React.Fragment>
                  <Row className="highlighted">
                    <Col className="d-flex justify-content-end align-items-center">
                      Access
                    </Col>
                    <Col className="d-flex justify-content-start grow-3">
                      <Form.Select
                        onChange={handleChangeLevel}
                        value={updatedItem.level}
                      >
                        <option value={"Manager"}>Manager</option>
                        <option value={"Marketer"}>Marketer</option>
                        <option value={"Viewer"}>Viewer</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="highlighted">
                    <Col className="d-flex justify-content-end align-items-center">
                      Status
                    </Col>
                    <Col className="d-flex justify-content-start grow-3">
                      <Form.Select
                        onChange={handleChangeStatus}
                        value={updatedItem.status}
                      >
                        <option value={"Active"}>Active</option>
                        <option value={"Deactivated"}>Deactivated</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              <Row className="highlighted padded-bottom-10">
                <Col className="d-flex justify-content-end align-items-center"></Col>
                <Col className="d-flex justify-content-start grow-3">
                  <Button className="button-campaign" onClick={handleSave}>
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Row>
          )}
        </React.Fragment>
      ))}
    </Col>
  );
}
