import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../appContext";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { getOrder } from "../services/svcOrders";
import { services } from "../config/typeNames";
import { getPhotoURL, uploadPhotos } from "../services/svcImages";
import IonIcon from "@reacticons/ionicons";
import { writeDocument, deleteDocument, moveFile } from "../services/firestore";
import { getClient, writeClient } from "../services/svcClient";
import { getDesigner } from "../services/svcUsers";

const niceDate = (date) => {
  const d = new Date(date);
  return d.toLocaleString();
};

export default function CardOrderManage(props) {
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const navigate = useNavigate();
  const id = props.id;
  const [order, setOrder] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [newDesc, setNewDesc] = useState("");
  const [revising, setRevising] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [viewingFiles, setViewingFiles] = useState(false);
  const [viewingFilesIcon, setViewingFilesIcon] = useState(
    "chevron-down-outline"
  );
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [badFile, setBadFile] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [stars, setStars] = useState(5);
  const [processing, setProcessing] = useState(false);
  const inputRef = useRef(null);
  const maxFilesRef = useRef(0);
  const filesCountRef = useRef(0);

  useEffect(() => {
    const loadOrder = async () => {
      const orderData = await getOrder("ready", id);
      setOrder(orderData);
      const url = await getPhotoURL(orderData.data.designerFile);
      setPhotoURL(url);
      const filesArray = [...orderData.data.files];
      setNewFiles(filesArray);
      const maxFilesLength = filesArray.length + 3;
      maxFilesRef.current = maxFilesLength;
      filesCountRef.current = filesArray.length;
      setLoaded(true);
    };
    loadOrder();
  }, [id]);

  const handleChangeDesc = (e) => {
    setNewDesc(e.target.value);
  };

  const handleRequestRevision = () => {
    setRevising(true);
  };

  const handleCancelRevision = () => {
    setRevising(false);
  };

  const handleSetStars = (num) => {
    setStars(num);
  };

  const handleFinalizeRevision = async () => {
    //-- set the new orderNotes
    const newNote = niceDate(Date.now()) + " CLIENT: " + newDesc;
    const newNotes = [...order.data.orderNotes];
    newNotes.unshift(newNote);
    //-- update the order record
    const newOrderData = { ...order.data };
    newOrderData.orderNotes = [...newNotes];
    newOrderData.revisions = newOrderData.revisions - 1;
    newOrderData.phase = "working";
    newOrderData.files = [...newFiles];
    const newOrder = { ...order };
    newOrder.data = newOrderData;
    newOrder.response = new Date().getTime();
    //-- move the order to orders-working
    let goodTransfer = true;
    try {
      const result = await writeDocument("orders-working", id, newOrder);
      if (result) {
        await deleteDocument("orders-ready", id);
      }
    } catch (err) {
      console.log("ORDER MANAGE ERROR: " + err);
      goodTransfer = false;
    }
    //-- finally, update orders in the client record
    if (goodTransfer) {
      //-- update the order object
      const clientRecord = await getClient(order.clientID);
      const newOrders = [...clientRecord.orders];
      const index = newOrders.findIndex((order) => order.orderID === id);
      const updatedOrder = { ...newOrders[index] };
      updatedOrder.phase = "working";
      newOrders[index] = updatedOrder;
      clientRecord.orders = [...newOrders];
      //-- write it bck to the client record
      await writeClient(clientRecord);
      //-- navigate back to admin
      setRevising(false);
      navigate("/admin");
    }
  };

  const handleAcceptDesign = async () => {
    //-- reveal the designer rating component
    if (!showRating) {
      setShowRating(true);
      return;
    }
    setProcessing(true);
    //-- update the order record
    const finalOrderData = { ...order.data };
    const timeAccepted = new Date().getTime();
    const orderDuration = timeAccepted - order.submitted;
    const orderHours = Math.floor(orderDuration / 3600000);
    finalOrderData.phase = "complete";
    finalOrderData.rating = stars;
    finalOrderData.hours = orderHours;
    finalOrderData.accepted = timeAccepted;
    finalOrderData.acceptedBy = user.uid;
    finalOrderData.designer = order.designer;
    const finalOrder = { data: finalOrderData };
    //-- move the order to orders-complete
    let goodTransfer = true;
    try {
      const result = await writeDocument("orders-complete", id, finalOrder);
      if (result) {
        deleteDocument("orders-ready", id);
      }
    } catch (err) {
      console.log("ORDER MOVE ERROR: " + err);
      goodTransfer = false;
    }
    //-- update the client record
    if (goodTransfer) {
      //-- update the client orders array
      const clientRecord = await getClient(order.clientID);
      const newOrders = [...clientRecord.orders];
      const index = newOrders.findIndex((order) => order.orderID === id);
      const updatedOrder = { ...newOrders[index] };
      updatedOrder.phase = "complete";
      updatedOrder.completed = timeAccepted;
      newOrders[index] = updatedOrder;
      clientRecord.orders = [...newOrders];
      //-- move the designer file to the images collection
      await moveFile(finalOrderData.designerFile, "photos", "images");
      //-- add the designer file to the client library
      let newLibrary = [];
      if ("library" in clientRecord) {
        newLibrary = [...clientRecord.library];
      }
      newLibrary.unshift(finalOrderData.designerFile);
      clientRecord.library = [...newLibrary];
      //-- write it back to the client record
      await writeClient(clientRecord);
      //-- update the designer records
      const designerData = await getDesigner(order.designer);
      const designerRecord = designerData.data;
      //-- create a new order summary item
      const newOrderItem = {
        orderID: id,
        completed: new Date().getTime(),
        rating: stars,
        hours: orderHours,
        clientName: clientRecord.clientName,
      };
      //-- delete any order items older than 90 days
      const newCompletedOrders = [...designerRecord.completedOrders];
      const ninetyDaysAgo = new Date().getTime() - 7776000000;
      const newCompletedOrdersFiltered = newCompletedOrders.filter((order) => {
        return order.completed > ninetyDaysAgo;
      });
      //-- add the new order item
      newCompletedOrdersFiltered.push(newOrderItem);
      //-- recalculate the average rating and hours
      let totalHours = 0;
      let totalRating = 0;
      newCompletedOrdersFiltered.forEach((order) => {
        totalHours = totalHours + order.hours;
        totalRating = totalRating + order.rating;
      });
      const avgHours = totalHours / newCompletedOrdersFiltered.length;
      let avgRating = (totalRating / newCompletedOrdersFiltered.length) * 10;
      avgRating = Math.round(avgRating);
      avgRating = avgRating / 10;
      //-- update the designer record
      designerRecord.completedOrders = [...newCompletedOrdersFiltered];
      designerRecord.avgHours = avgHours;
      designerRecord.avgRating = avgRating;
      //-- write it back to the designer record
      const newDesignerRecord = { data: designerRecord };
      writeDocument("designers", order.designer, newDesignerRecord);
    }
    //-- navigate back to admin
    setProcessing(false);
    navigate("/admin");
  };

  const toggleViewFiles = () => {
    if (viewingFiles) {
      setViewingFilesIcon("chevron-down-outline");
    } else {
      setViewingFilesIcon("chevron-up-outline");
    }
    setViewingFiles(!viewingFiles);
  };

  const handleFileChange = async (e) => {
    const fileObj = e.target.files && e.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      setBadFile(true);
      return;
    }
    //-- check the file type
    if (fileObj.type !== "image/jpeg" && fileObj.type !== "image/png") {
      setBadFile(true);
      return;
    }
    //-- check the file size
    if (fileObj.size > 2000000) {
      setBadFile(true);
      return;
    }

    //-- upload the file
    await uploadFile(fileObj);
  };

  const uploadFile = async (fileObj) => {
    try {
      //-- upload to storage
      const filename = await uploadPhotos(fileObj);

      //-- add file to array
      const filesList = [...newFiles];
      filesList.push(filename);
      setNewFiles(filesList);
      filesCountRef.current = filesList.length;

      //- enable uploads button again
      if (filesCountRef.current < maxFilesRef.current) {
        setUploadDisabled(false);
      }
      return;

      //-- catch any errors
    } catch (err) {
      console.log("uploadPhoto: ERROR: " + err);
      setBadFile(true);
      if (filesCountRef.current < maxFilesRef.current) {
        setUploadDisabled(false);
      }
    }
  };

  const handleClick = () => {
    setUploadDisabled(true);
    setBadFile(false);
    //-- if user cancels, this turns the uploader back on after 8 seconds
    setTimeout(() => {
      if (filesCountRef.current < maxFilesRef.current) {
        setUploadDisabled(false);
      }
    }, 8000);
    inputRef.current.click();
  };

  return (
    <Col className="dash-card big-bottom">
      <Row className="title-row">
        <h3>Design Order</h3>
      </Row>
      {loaded && (
        <React.Fragment>
          <Row>
            <p>
              OrderID: {order.data.id} - {services[order.data.couponType]}{" "}
              Coupon
            </p>
          </Row>
          <Row className="big-bottom">
            <p>
              <b>{order.data.orderNotes[0]}</b>
            </p>
          </Row>
          <Row>
            <Image src={photoURL} />
          </Row>
          {showRating && (
            <Row className="v-top-10">
              <h5 className="text-center">
                Please rate your design experience:
              </h5>
              <Row className="justify-content-center stars-color">
                <IonIcon
                  onClick={() => handleSetStars(1)}
                  name="star"
                  size="large"
                />
                <IonIcon
                  onClick={() => handleSetStars(2)}
                  name={stars > 1 ? "star" : "star-outline"}
                  size="large"
                />
                <IonIcon
                  onClick={() => handleSetStars(3)}
                  name={stars > 2 ? "star" : "star-outline"}
                  size="large"
                />
                <IonIcon
                  onClick={() => handleSetStars(4)}
                  name={stars > 3 ? "star" : "star-outline"}
                  size="large"
                />
                <IonIcon
                  onClick={() => handleSetStars(5)}
                  name={stars > 4 ? "star" : "star-outline"}
                  size="large"
                />
              </Row>
            </Row>
          )}
          {revising ? (
            <React.Fragment>
              <Row>
                <Col className="d-flex justify-content-left align-items-center v-top-10">
                  <IonIcon
                    onClick={() => toggleViewFiles()}
                    className="snagm-icons"
                    name={viewingFilesIcon}
                    size="large"
                  />
                  <p>&nbsp;View or attach JPEG or PNG files</p>
                </Col>
              </Row>
              {viewingFiles && (
                <React.Fragment>
                  {newFiles.map((file, index) => (
                    <Row key={index} className="v-top-10">
                      <Col className="d-flex justify-content-center">
                        <p>{file}</p>
                      </Col>
                    </Row>
                  ))}
                  <Row className="padded-row v-top-10">
                    <Alert variant="danger" show={badFile}>
                      <p>
                        Please upload a JPEG or PNG file that is less than 2MB
                        in size.
                      </p>
                    </Alert>
                  </Row>
                  <Row className="v-top-10">
                    <Col className="d-flex justify-content-center">
                      <input
                        style={{ display: "none" }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Button
                        onClick={() => handleClick()}
                        disabled={uploadDisabled}
                        size="sm"
                        className="button-snagm"
                      >
                        UPLOAD FILE
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              <Row className="v-top-10 padded-row">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Note to your designer..."
                  onChange={handleChangeDesc}
                  value={newDesc}
                />
              </Row>
              <Row className="v-top-10">
                <p className="text-center">
                  You have {order.data.revisions}
                  {order.data.revisions === 1 ? " revision" : " revisions"}{" "}
                  left.
                </p>
              </Row>
              <Row className="campaign-row big-bottom">
                <Col className="d-flex">
                  <Button
                    onClick={() => handleCancelRevision()}
                    className="button-campaign-secondary w-100"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className="d-flex">
                  <Button
                    disabled={order.data.revisions === 0}
                    onClick={() => handleFinalizeRevision()}
                    className="button-campaign w-100"
                  >
                    Request Revision
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row className="campaign-row big-bottom">
                <Col className="d-flex">
                  <Button
                    disabled={processing}
                    onClick={() => handleAcceptDesign()}
                    className="button-campaign-success w-100"
                  >
                    Accept Design
                  </Button>
                </Col>
                <Col className="d-flex">
                  <Button
                    disabled={showRating}
                    onClick={() => handleRequestRevision()}
                    className="button-campaign w-100"
                  >
                    Request Revision
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row className="padded-row">
            <p>Order Notes:</p>
            {order.data.orderNotes.map((note, index) => (
              <p key={index}>{note}</p>
            ))}
          </Row>
        </React.Fragment>
      )}
    </Col>
  );
}
