import React, { useEffect, useState, useContext } from "react";
import { ProfileContext } from "./profileContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardAssets from "./cardAssets";
import { getClient, writeClient } from "../services/svcClient";

export default function DashAssets() {
  const profileRecord = useContext(ProfileContext);
  const profileData = profileRecord.profile;
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});
  const [assetsList, setAssetsList] = useState([]);

  const updateClientAssets = async (newAssets) => {
    const newClientData = { ...clientData };
    newClientData.assets = newAssets;
    await writeClient(newClientData);
    setClientData(newClientData);
    setAssetsList(newAssets);
  };

  useEffect(() => {
    const loadClient = async () => {
      const clientID = profileData.clientID;
      const data = await getClient(clientID);
      setClientData(data);
      if ("assets" in data) {
        setAssetsList(data.assets);
      }
      setLoading(false);
    };
    loadClient();
  }, [profileData]);

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardAssets
            assetsList={assetsList}
            updateClientAssets={updateClientAssets}
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
