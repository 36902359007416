import React from "react";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardUserStaff from "./cardUserStaff";

export default function DashStaffAdmin() {
  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardUserStaff />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
