import React, { useState, useContext, useEffect } from "react";
import { ProfileContext } from "./profileContext";
import "../css/cards.css";
import "../css/tables.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { Dots } from "react-activity";
import { getValidators } from "../services/svcUsers";
import { genID, wait } from "../services/svcFunctions";
import { writeDocument, deleteDocument } from "../services/firestore";

export default function CardUserStaff() {
  const profileRecord = useContext(ProfileContext);
  const profileData = profileRecord.profile;
  const [showEdit, setShowEdit] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [validName, setValidName] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [badName, setBadName] = useState(false);

  useEffect(() => {
    const loadStaffList = async () => {
      const staffList = await getValidators(profileData.clientID);
      setStaffList(staffList);
      setLoading(false);
    };
    loadStaffList();
  }, [profileData.clientID]);

  const toggleEdit = () => {
    if (showEdit) {
      setShowEdit(false);
      setNewUser({});
      setBadName(false);
      setDisabled(true);
      setValidName(false);
      setValidPwd(false);
    } else {
      setShowEdit(true);
    }
  };

  const checkLoginName = async (loginName) => {
    let finalData = {
      result: "INVALID",
    };
    const bodyData = {
      name: loginName,
    };
    const bodyString = JSON.stringify(bodyData);
    await fetch(
      "https://us-central1-snagm-b6068.cloudfunctions.net/checkName",
      {
        method: "POST",
        body: bodyString,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        finalData = data;
      })
      .catch((error) => {
        console.log("ERROR CHECKING NEW NAME: ", error);
        return false;
      });
    if (finalData.result === "VALID") {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeName = (e) => {
    const newVal = e.target.value;
    const newRecord = { ...newUser };
    newRecord.name = newVal;
    setNewUser(newRecord);
    if (newVal.length > 0) {
      setValidName(true);
      if (validPwd) {
        setDisabled(false);
      }
    } else {
      setValidName(false);
      setDisabled(true);
    }
  };

  const handleChangePwd = (e) => {
    const newVal = e.target.value;
    if (newVal.length > 12) {
      return;
    }
    const newRecord = { ...newUser };
    newRecord.password = newVal;
    setNewUser(newRecord);
    if (newVal.length > 5) {
      setValidPwd(true);
      if (validName) {
        setDisabled(false);
      }
    } else {
      setValidPwd(false);
      setDisabled(true);
    }
  };

  const handleAddUser = async () => {
    setProcessing(true);
    const goodName = await checkLoginName(newUser.name);
    if (!goodName) {
      setBadName(true);
      setProcessing(false);
      return;
    }
    const key = genID(12);
    const newRecord = { ...newUser };
    newRecord.added = Date.now();
    newRecord.addedBy = profileData.first + " " + profileData.last;
    newRecord.id = key;
    const newStaffList = [...staffList];
    newStaffList.push(newRecord);
    const documentRecord = {
      clientID: profileData.clientID,
      name: newUser.name,
      data: newRecord,
    };
    await writeDocument("validators", key, documentRecord);
    setStaffList(newStaffList);
    setProcessing(false);
    toggleEdit();
  };

  const handleDelete = async (id) => {
    const newStaffList = [...staffList];
    const index = newStaffList.findIndex((item) => item.id === id);
    newStaffList.splice(index, 1);
    setStaffList(newStaffList);
    await wait(1000);
    deleteDocument("validators", id);
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Validators</h3>
        </Row>
        <Row className="d-flex title-row margin-left-20">
          <Dots color="#000000" size={24} speed={1} animating={true} />
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row big-bottom">
        <Col className="d-flex align-items-center">
          <h3>Validators</h3>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button className="button-add" onClick={() => toggleEdit()}>
            Add
          </Button>
        </Col>
      </Row>
      {showEdit && (
        <React.Fragment>
          <Row className="d-flex highlighted padded-top-20 h-margin-reset big-bottom">
            {badName && (
              <Alert className="d-flex center-stuff" variant="warning">
                Sorry, that Login Name is already taken.
              </Alert>
            )}
            <Row>
              <Col className="d-flex justify-content-end align-items-center">
                <p>Login Name:</p>
              </Col>
              <Col className="d-flex justify-content-start align-items-center grow-2">
                <Form.Control
                  type="text"
                  id="first"
                  value={newUser.name}
                  onChange={handleChangeName}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end align-items-center">
                <p>Password:</p>
              </Col>
              <Col className="d-flex justify-content-start align-items-center grow-2">
                <Form.Control
                  type="text"
                  id="phone"
                  value={newUser.password}
                  onChange={handleChangePwd}
                />
              </Col>
            </Row>
            <Row className="padded-top-10 padded-bottom-20">
              <Col></Col>
              {processing ? (
                <Col>
                  <Button disabled={true} variant="primary">
                    <Dots />
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    disabled={disabled}
                    variant="primary"
                    onClick={handleAddUser}
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>
          </Row>
        </React.Fragment>
      )}
      <Table>
        <thead>
          <tr className="tiny-text">
            <th>NAME</th>
            <th className="d-flex justify-content-center">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {staffList.map((item, index) => {
            return (
              <React.Fragment>
                <tr key={index}>
                  <td>{item.name}</td>
                  <React.Fragment>
                    <td>
                      <Badge
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => handleDelete(item.id)}
                        bg="danger"
                        pill
                      >
                        DELETE
                      </Badge>
                    </td>
                  </React.Fragment>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
}
