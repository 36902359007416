import React, { useContext } from "react";
import { ProfileContext } from "./profileContext";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default function CardAccount() {
  const profileRecord = useContext(ProfileContext);
  const profileData = profileRecord.profile.data;
  const navigate = useNavigate();

  const goScreen = (urlTarget) => {
    navigate(urlTarget);
  };

  return (
    <Col className="dash-card">
      <Row>
        <h3>Users and Settings</h3>
      </Row>
      <Row className="campaign-row">
        <Button className="button-campaign" onClick={() => goScreen("users")}>
          Manage Admin Users
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button className="button-campaign" onClick={() => goScreen("staff")}>
          Manage Validators
        </Button>
      </Row>
      <Row className="campaign-row">
        <Button
          className="button-campaign"
          disabled={profileData.level !== "Owner"}
          onClick={() => goScreen("settings")}
        >
          Main Settings
        </Button>
      </Row>
    </Col>
  );
}
