import React, { useState, useContext } from "react";
import { AppContext } from "../appContext";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getApp } from "firebase/app";
import "../css/login.css";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Header from "./header";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const alertMsg = "Wrong username or password.";

  const changeEmail = (newText) => {
    const text = newText.currentTarget.value;
    setEmail(text);
  };

  const changePassword = (newText) => {
    const text = newText.currentTarget.value;
    setPassword(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const app = getApp();
    const auth = getAuth(app);
    await signInWithEmailAndPassword(auth, email, password)
      .then((credential) => {
        console.log("login: USER LOGGED IN");
        navigate("/admin");
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log("SIGN IN ERROR:", errorMessage);
        setShowAlert(true);
      });
  };

  return (
    <Container fluid>
      {user && <Navigate to="/admin" replace={true} />}
      <Header login={true} />
      <Row className="login-section d-flex justify-content-center">
        <Col lg={true} className="login-box">
          <Row>
            <Form onSubmit={handleSubmit}>
              <Row className="login-title">Admin Portal</Row>
              <Row>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(newText) => changeEmail(newText)}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(newText) => changePassword(newText)}
                  />
                </Form.Group>
              </Row>
              {showAlert && (
                <Row className="alert-box">
                  <Alert variant="danger">{alertMsg}</Alert>
                </Row>
              )}
              <Row className="login-button">
                <Button type="submit" variant="success">
                  Sign In
                </Button>
              </Row>
              <Row className="forgot-box">
                <Link className="d-flex justify-content-center" to={"/reset"}>
                  Reset Your Password
                </Link>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
