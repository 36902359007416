import { writeDocument, readDocument } from "./firestore";
import { isoToday } from "./svcFunctions";

export const trackPhoto = async (filename) => {
  const docKey = filename.slice(0, -4);
  const newDoc = {
    uploaded: isoToday(),
  };
  await writeDocument("uploads", docKey, newDoc);
  return;
};

export const saveOrder = async (order, id) => {
  await writeDocument("orders-pending", id, order);
  return;
};

export const getOrder = async (phase, id) => {
  const collectionName = `orders-${phase}`;
  const doc = await readDocument(collectionName, id);
  return doc;
};
