import React, { useContext } from "react";
import { ProfileContext } from "./profileContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardSignage from "./cardSignage";

export default function DashSignage() {
  const profileRecord = useContext(ProfileContext);
  const profileData = profileRecord.profile;

  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardSignage clientID={profileData.clientID} />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}
