import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { getClient, writeClient } from "../services/svcClient";
import { getImageURL } from "../services/svcImages";

export default function CardSettings(props) {
  const navigate = useNavigate();
  const { clientID } = props;
  const [clientData, setClientData] = useState({});
  const [logoURL, setLogoURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const getClientData = async () => {
      if (!clientID) return;
      const clientData = await getClient(clientID);
      if (clientData) {
        setClientData(clientData);
        const logoURL = await getImageURL(clientData.logo);
        setLogoURL(logoURL);
        setLoading(false);
      }
    };
    getClientData();
  }, [clientID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSaveChanges = async () => {
    setProcessing(true);
    await writeClient(clientData);
    navigate("/admin");
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Main Settings</h3>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row big-bottom">
        <h3>Account Settings</h3>
        <p>To change your Name or Website, please contact us.</p>
      </Row>
      <Row className="padded-row big-bottom">
        <p>Display Name</p>
        <h5>{clientData.clientName}</h5>
      </Row>
      <Row className="padded-row big-bottom">
        <p>Your Website URL</p>
        <a href={clientData.url}>
          <h6>{clientData.url}</h6>
        </a>
      </Row>
      <Row className="padded-row big-bottom">
        <p>Subscriber Logo Banner</p>
        <Image src={logoURL} />
      </Row>
      <Row className="padded-row big-bottom">
        <hr />
      </Row>
      <Row className="padded-row">
        <p>Terms and Conditions</p>
        <p className="tiny-text">
          This pre-fills new coupons to help save time...
        </p>
        <Form.Control
          as="textarea"
          rows={3}
          name="terms"
          value={clientData.terms}
          onChange={handleChange}
        />
      </Row>
      <Row className="campaign-row">
        <Button
          disabled={processing}
          onClick={() => handleSaveChanges()}
          className="button-campaign"
        >
          Save Changes
        </Button>
      </Row>
    </Col>
  );
}
