import React, { useEffect, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { queryCollection } from "../services/firestore";
import { services } from "../config/typeNames";

export default function CardRedeemDetail(props) {
  const [detailData, setDetailData] = useState([]);
  const [showItem, setShowItem] = useState(-1);
  const clientID = props.clientID;

  useEffect(() => {
    async function fetchData() {
      const dataReturn = await queryCollection("redeems", "clientID", clientID);
      //-- iterate each document and pull data into a results array
      const results = [];
      dataReturn.forEach((doc) => {
        const redeemData = doc.data;
        const couponData = redeemData.coupon;
        const timestamp = new Date(doc.redeemed);
        const timestampDisplay = timestamp.toDateString();
        const validatedTime = timestamp.toLocaleTimeString();
        const compactRecord = {
          type: couponData.type,
          code: redeemData.code,
          timestamp: timestampDisplay,
          validated: redeemData.validated,
          redeemed: doc.redeemed,
          validatedTime: validatedTime,
        };
        results.push(compactRecord);
      });
      //-- sort the results array by redeemed date
      results.sort((a, b) => {
        return b.redeemed - a.redeemed;
      });
      setDetailData(results);
    }
    fetchData();
  }, [clientID]);

  const toggleShowItem = (index) => {
    if (showItem === index) {
      setShowItem(-1);
    } else {
      setShowItem(index);
    }
  };

  return (
    <Col className="dash-card">
      <Row className="d-flex title-row">
        <Col className="d-flex grow-3">
          <h3>Redemption Details</h3>
        </Col>
      </Row>
      <Row className="d-flex tiny-text">
        <Col className="d-flex justify-content-start">TYPE</Col>
        <Col className="d-flex justify-content-end">REDEEMED</Col>
      </Row>
      {detailData.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Row
              className="d-flex v-top-5"
              onClick={() => toggleShowItem(index)}
            >
              <Col className="d-flex justify-content-start">
                {services[item.type]}
              </Col>
              <Col className="d-flex justify-content-end">{item.timestamp}</Col>
            </Row>
            {showItem === index && (
              <Row className="d-flex" key={index}>
                <Col className="d-flex justify-content-center">
                  {item.code} - {item.validated} - {item.validatedTime}
                </Col>
              </Row>
            )}
          </React.Fragment>
        );
      })}
    </Col>
  );
}
