import React from "react";
import "../css/landing.css";
import hero from "../assets/snagm-hero.png";
import apple from "../assets/apple-black.svg";
import android from "../assets/google-play.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Header from "./header";

export default function Landing() {
  return (
    <Container fluid className="top-container">
      <Header />
      <Row className="hero-section">
        <Col
          lg={true}
          className="hero-box d-flex flex-column justify-content-center"
        >
          <Row className="hero-title">
            <h1
              style={{ color: "#EEEEF1", marginTop: 30, textAlign: "center" }}
            >
              Get the coupons you really want
            </h1>
          </Row>
          <Row>
            <h4
              style={{ color: "#EEEEF1", marginTop: 20, textAlign: "center" }}
            >
              Follow your favorite places to get loyalty discounts, birthday
              specials, and exclusive deals!
            </h4>
          </Row>
          <Row className="downloads">
            <Col className="download-box d-flex justify-content-center">
              <Image className="download-apple" src={apple} />
            </Col>
            <Col className="download-box d-flex justify-content-center">
              <Image className="download-android" src={android} />
            </Col>
          </Row>
        </Col>
        <Col lg={true} className="hero-box">
          <Image className="hero-image img-fluid" src={hero} />
        </Col>
      </Row>
      <Row className="footer-section">
        <Col lg={true} className="footer-box">
          FOOTER Left
        </Col>
        <Col lg={true} className="footer-box">
          FOOTER Right
        </Col>
      </Row>
    </Container>
  );
}
