import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "./profileContext";
import { AppContext } from "../appContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardSubs from "./cardSubs";
import CardRedeem from "./cardRedeem";
import CardAccount from "./cardAccount";
import CardOrders from "./cardOrders";
import CardDiscover from "./cardDiscover";
import CardInbox from "./cardInbox";
import CardBirthday from "./cardBirthday";
import CardHelp from "./cardHelp";
import { getClient } from "../services/svcClient";
import { getProfile } from "../services/svcUsers";
import { wait } from "../services/svcFunctions";

export default function DashHome() {
  const navigate = useNavigate();
  const profile = useContext(ProfileContext);
  const user = useContext(AppContext);
  const userID = user.user.uid;
  const [isReady, setIsReady] = useState(false);
  const [clientData, setClientData] = useState();
  const [userLevel, setUserLevel] = useState();

  useEffect(() => {
    const loadProfile = async () => {
      const data = profile.profile;
      if (data) {
        const client = await getClient(data.clientID);
        setClientData(client);
        const profileData = data.data;
        setUserLevel(profileData.level);
        if (!profileData.agreedToTerms) {
          //-- double check if we just agreed in this session
          await wait(1000);
          const profileCheck = await getProfile(userID);
          if (!profileCheck.data.agreedToTerms) {
            navigate("/admin/terms");
          }
        }
        setIsReady(true);
      }
    };
    loadProfile();
  }, [profile, navigate, userID]);

  return (
    <Container fluid>
      <Header />
      {isReady && (
        <Row className="grid-container main-row">
          <Col>
            <h4 className="dash-title">{clientData.clientName}</h4>
            <CardSubs clientData={clientData} />
          </Col>
          <Col>
            <CardRedeem clientData={clientData} />
          </Col>
          <Col>
            <CardDiscover clientData={clientData} />
          </Col>
          <Col>
            <CardInbox clientData={clientData} />
          </Col>
          <Col>
            <CardBirthday clientData={clientData} />
          </Col>
          {userLevel === "Owner" || userLevel === "Manager" ? (
            <React.Fragment>
              <Col>
                <CardOrders clientData={clientData} />
              </Col>
              <Col>
                <CardAccount clientData={clientData} />
              </Col>
              <Col>
                <CardHelp clientData={clientData} />
              </Col>
            </React.Fragment>
          ) : (
            <Col></Col>
          )}
        </Row>
      )}
    </Container>
  );
}
