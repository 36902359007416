import { readDocument, updateDocument, writeDocument } from "./firestore";

export async function getClient(id) {
  try {
    const clientRecord = await readDocument("clients", id);
    const clientData = clientRecord.data;
    return clientData;
  } catch (error) {
    console.log("GET CLIENT ERROR: ", error);
    return null;
  }
}

export async function writeClient(data) {
  const clientRecord = {
    zip: data.clientZIP,
    data: data,
    deployment: data.cityState,
  };
  const clientData = await writeDocument(
    "clients",
    data.clientID,
    clientRecord
  );
  return clientData;
}

export async function updateClient(id, data) {
  const clientRecord = {
    zip: data.clientZIP,
    data: data,
    deployment: data.cityState,
  };
  const clientData = await updateDocument("clients", id, clientRecord);
  return clientData;
}

export async function lookupClient(id) {
  const clientRecord = await readDocument("client-lookup", id);
  const clientData = clientRecord.data;
  return clientData;
}
