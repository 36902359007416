import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default function CardHelp(props) {
  const { clientData } = props;
  const subject = "Help Request from " + clientData.clientName;
  const emailLink = "mailto:support@snagm.com?subject=" + subject;
  const navigate = useNavigate();

  const goScreen = (urlTarget) => {
    navigate(urlTarget);
  };

  return (
    <Col className="dash-card big-bottom">
      <Row className="title-row">
        <h3>Help</h3>
      </Row>
      <Row className="campaign-row">
        <Button
          className="button-add big-bottom"
          onClick={() => goScreen("signage")}
        >
          Request Signage
        </Button>
      </Row>
      <Row>
        <p>
          <b>How To Guides</b>
        </p>
      </Row>
      <Row>
        <p>Coming soon!</p>
      </Row>
      <Row className="padded-row v-top-10">
        <hr />
      </Row>
      <Row>
        <p>
          <b>Questions? Need Assistance?</b>
        </p>
      </Row>
      <Row>
        <p>
          Contact us at:&nbsp;
          <a href={emailLink}>support@snagm.com</a>
        </p>
      </Row>
    </Col>
  );
}
